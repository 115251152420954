import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, SectionTitle, SocialCard } from 'components';
import { useChatbot } from 'hooks/useChatbot';
import queryString from 'query-string';

import BgContactImg from 'images/bg-contact.png';
import Bot from 'images/img-banner-paynebot.png';
import Christian from 'images/christian-black-tee.png';

const StyledContact = styled.div`
  background: linear-gradient(
    84deg,
    rgba(66, 161, 219, 1) 28%,
    rgba(44, 117, 195, 1) 94%
  );

  p,
  h3 {
    margin: 0;
    line-height: 1.2;
  }

  p {
    line-height: 1.8;
    margin-top: 5px;
  }

  .circle {
    background: white;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.06);
    width: 75px;
    height: 75px;
    margin-top: -10px;
  }

  .contact-bg {
    background: url(${BgContactImg}) left bottom no-repeat;
  }

  .talk-to-bot {
    border-radius: 17px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    background: var(--light-blue);
    color: black;
    padding: 0;

    p {
      font-style: italic;
    }

    .uk-card-footer {
      border-bottom-right-radius: 17px;
      border-bottom-left-radius: 17px;
      background-color: white;
      padding: 36px;
    }
  }

  .contact-form {
    padding: 0;
    border-radius: 17px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);

    .uk-card-header {
      padding: 36px;
      border-top-right-radius: 17px;
      border-top-left-radius: 17px;
      background: var(--light-blue);
    }
  }

  .contact-form h2 {
    margin-bottom: 65px;
  }

  .contact-thank-you {
    margin-top: 120px;
  }
`;

const ContactPage = ({
  data: {
    prismicContactPage: {
      data: {
        title,
        heading,
        text,
        bot_button_text,
        social_heading,
        social_media,
        thank_you_text,
      },
    },
  },
  location: { search },
}) => {
  const [showThankYou, setShowThankYou] = React.useState(false);
  const { openChatbot } = useChatbot();

  React.useEffect(() => {
    setShowThankYou(!!queryString.parse(search).thankyou);
  }, [search]);

  return (
    <Layout>
      <StyledContact>
        <div
          className="uk-container uk-container-medium uk-padding-large contact-bg"
          uk-scrollspy="cls:uk-animation-fade"
        >
          <div className="uk-child-width-1-2@m" uk-grid="true">
            <div>
              <h1 className="uk-light">{title.text}</h1>
              <div className="uk-card uk-card-default talk-to-bot">
                <div className="uk-card-body">
                  <div className="uk-grid uk-grid-collapse" uk-grid="true">
                    <div className="uk-width-1-5">
                      <img className="circle" src={Bot} />
                    </div>
                    <div className="uk-width-4-5">
                      <h3>{heading.text}</h3>
                      <p>{text.text}</p>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer">
                  <button
                    className="uk-button uk-button-primary uk-width-1-1"
                    onClick={openChatbot}
                  >
                    {bot_button_text}
                  </button>
                </div>
              </div>
            </div>
            <div>
              {showThankYou ? (
                <div className="uk-card uk-card-default uk-card-body contact-form contact-thank-you">
                  <div className="uk-padding">
                    <h3>{thank_you_text.text}</h3>
                  </div>
                </div>
              ) : (
                <div className="uk-card uk-card-default uk-card-body contact-form">
                  <div class="uk-card-header">
                    <div className="uk-grid uk-grid-collapse" uk-grid="true">
                      <div className="uk-width-1-5">
                        <img className="circle" src={Christian} />
                      </div>
                      <div className="uk-width-4-5">
                        <h3>Send us a mail</h3>
                        <p>
                          Send a message here, and one of our human team members
                          will get back to you soon!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="uk-card-body">
                    <form
                      name="contact"
                      className="uk-form-stacked"
                      method="POST"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      action="/contact?thankyou=true"
                    >
                      <input type="hidden" name="bot-field" />
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="uk-margin-small-bottom">
                        <label className="uk-form-label">Name</label>
                        <div className="uk-form-controls">
                          <input
                            id="name"
                            name="name"
                            className="uk-input"
                            type="text"
                            placeholder="your name"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="uk-margin-small-bottom">
                        <label className="uk-form-label">Email</label>
                        <div className="uk-form-controls">
                          <input
                            id="email"
                            name="email"
                            className="uk-input"
                            type="text"
                            placeholder="your email"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="uk-margin-bottom">
                        <label className="uk-form-label">Message</label>
                        <div className="uk-form-controls">
                          <textarea
                            id="message"
                            name="message"
                            className="uk-textarea"
                            rows="7"
                            placeholder="message"
                            required={true}
                          ></textarea>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="uk-button uk-button-primary uk-width-1-1"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledContact>

      <div
        className="uk-container uk-container-medium uk-padding-large"
        uk-scrollspy="cls:uk-animation-fade"
      >
        <div
          className="uk-child-width-1-4@l uk-child-width-1-2@s uk-grid-match"
          uk-grid="true"
        >
          <div className="uk-width-1-1">
            <div className="uk-grid-small" uk-grid="true">
              <SectionTitle bulletColor="blue" title={social_heading.text} />
            </div>
          </div>
          {social_media.map(
            (
              {
                social_id,
                social_tags,
                social_url,
                social_icon,
                social_list,
                background,
              },
              i
            ) => (
              <SocialCard
                key={i}
                icon={social_icon}
                background={background.url}
                title={social_id}
                tags={social_tags.split(',')}
                bullets={social_list.raw.map((s) => s.text)}
                url={social_url.url}
              />
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    prismicContactPage {
      data {
        title {
          text
        }
        heading {
          text
        }
        text {
          text
        }
        bot_button_text
        social_media {
          background {
            url
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 512) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          social_id
          social_icon {
            url
          }
          social_url {
            url
          }
          social_tags
          social_list {
            raw
          }
        }
        social_heading {
          text
        }
        thank_you_text {
          text
        }
      }
    }
  }
`;
